import React from "react";
import BaseModal from "helper/component/basemodal";
import { useLocalize } from "helper";

const Info = () => {
	const [localize] = useLocalize();
	return (
		<BaseModal tag={"info"} title={localize("txt_info")} size="big">
			<div className="title">{localize("txt_popup_info_title")}</div>
			<div className="rules__container">
				<div
					className="text gunar"
					dangerouslySetInnerHTML={{ __html: localize("txt_event_info") }}
				/>
			</div>
		</BaseModal>
	);
};

export default Info;
