import React from "react";
import Modal from "react-responsive-modal";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";

function BaseModal({
	title,
	children,
	tag,
	size = "big",
	onExit = () => {},
	...props
}) {
	const dispatch = useDispatch();
	const onClose = () => {
		onExit();
		dispatch(toggleModal({ name: tag }));
	};
	const isOpen = useSelector((state) => state.modal.name[tag]);
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center={true}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			blockScroll={false}
			classNames={{
				modal: `modal modal-${tag} popup-${size}`,
				overlay: `modal__overlay overlay-${tag}`,
				closeButton: `btn-close close-${tag}`,
				modalAnimationIn: `scaleY`,
				modalAnimationOut: `scaleYreverse`,
			}}
			closeIcon={<div className="ico-close" />}
			showCloseIcon={true}
			focusTrapped={false}
			container={document.getElementById("Main")}
			{...props}
		>
			<div className={`modal__container ${tag}`}>
				{title && (
					<div className={`modal__title ${tag}`}>
						<h3>{title}</h3>
					</div>
				)}
				<div className={`modal__content ${tag}`}>{children}</div>
			</div>
		</Modal>
	);
}

export default BaseModal;
