import { useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";

function useLocalize() {
    const localize = useSelector(state => state.main.localize, shallowEqual);
    const fun = useCallback((key) => {
        return localize?.[key] || key;
    }, [localize]);
    return [fun, localize];
}

export default useLocalize;
