import React from "react";
import ConfirmSpin from "./ConfirmSpin";
import ConfirmExchange from "./ConfirmExchange";
import ConfirmShards from "./ConfirmShards";
import History from "./History";
import Info from "./Info";
import Policy from "./Policy";
import TestModal from "./Test";
import Congrats from "./Congrats";
import Pools from "./Pools";

const ModalCont = () => {
	return (
		<>
			<TestModal />
			<History />
			<ConfirmSpin />
			<ConfirmExchange />
			<ConfirmShards />
			<Congrats />
			<Pools />
			<Policy />
			<Info />
		</>
	);
};

export default ModalCont;
