import { useLocalize } from "helper";
import BaseModal from "helper/component/basemodal";
import React from "react";
import { useSelector } from "react-redux";

const tag = "pools";

const data = {
	cp: 50,
	fragment_count: 24,
	emblem_count: 0,
	prize: [
		{
			name: "Credits*50",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/1880000001_x50.png",
		},
		{
			name: "Blue XP Card",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/320000002.png",
		},
		{
			name: "Credits*50",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/1880000001_x50.png",
		},
		{
			name: "Blue XP Card",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/320000002.png",
		},
		{
			name: "Credits*50",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/1880000001_x50.png",
		},
		{
			name: "Blue XP Card",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/320000002.png",
		},
		{
			name: "Credits*50",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/1880000001_x50.png",
		},
		{
			name: "Blue XP Card",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/320000002.png",
		},
		{
			name: "Credits*50",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/1880000001_x50.png",
		},
		{
			name: "Blue XP Card",
			qty: 1,
			img_url:
				"https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/h5/20221018_SecretShop/Spin10/320000002.png",
		},
	],
};

export const TYPE_FRAGMENT = "fragment";
export const TYPE_EMBLEM = "emblem";

const Pools = () => {
	const [localize] = useLocalize();
	const pools = useSelector((state) => state.main.pools);
	return (
		<BaseModal tag={tag} title={localize("txt_popup")}>
			<div className="title">{localize("txt_reward_list")}</div>
			<div
				className={`items__container ${
					data.prize.length > 1 ? "multiple-items" : "single-items"
				}`}
			>
				<div className="items__wrapper">
					<div className="items__content">
						{pools.map((data, idx) => {
							const frameClass = [
								"item__frame",
								data.item_type === TYPE_FRAGMENT
									? // data.item_type === TYPE_EMBLEM
									  "non-item"
									: data.rarity,
							]
								.filter((d) => d)
								.join(" ");

							return (
								<div key={idx} className="item animated fadeIn">
									<div className={frameClass}>
										<div className="item__img">
											<img alt="" src={data.img_url} />
										</div>
									</div>
									<div className="item__name">{data.name}</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default Pools;
