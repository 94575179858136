import React from "react";
import BaseModal from "helper/component/basemodal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";
import { useLocalize } from "helper";
import { postShop } from "redux/actions/main";

const tag = "confirm-exchange";

const ConfirmTrade = () => {
	const dispatch = useDispatch();
	const [localize] = useLocalize();
	const selectedItem = useSelector(
		(state) => state.main.shop.find((f) => f.id === state.main.selectedId),
		shallowEqual
	);
	const frameClass = ["item__frame", selectedItem?.rarity]
		.filter((d) => d)
		.join(" ");
	return (
		<BaseModal tag={tag} title={localize("txt_system")} showCloseIcon={false}>
			<div className="title">{localize("txt_popup_confirm_title")}</div>
			<div
				className="text gunar"
				dangerouslySetInnerHTML={{
					__html: localize("txt_popup_confirm_buy_item")
						?.replace("{img}", `<div class="emblem-ico" ></div>`)
						?.replace("{num}", selectedItem?.price),
				}}
			/>

			<div className="item">
				<div className={frameClass}>
					<div className="item__img">
						<img alt="" src={selectedItem?.img_url} />
					</div>
				</div>
			</div>
			<div className="group">
				<button
					className="btn btn-grey"
					onClick={() => dispatch(toggleModal({ name: tag }))}
				>
					{localize("txt_popup_btn_back")}
				</button>
				<button
					className="btn btn-yellow"
					onClick={() => dispatch(postShop(selectedItem?.id))}
				>
					{localize("txt_confirm")}
				</button>
			</div>
		</BaseModal>
	);
};

export default ConfirmTrade;
