import React, { useState } from "react";
import BaseModal from "helper/component/basemodal";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";
import { useEffect } from "react";
import { useLocalize } from "helper";
import { postConvert } from "redux/actions/main";

const tag = "confirm-shards";

const ConfirmShards = () => {
	const dispatch = useDispatch();
	const [localize] = useLocalize();
	const ownedFragments = useSelector((state) => state.main.wallet.fragment);
	const convertRate = useSelector((state) => state.main.convertRate);
	const [shards, setShards] = useState(0);

	const roundDown = Math.floor(ownedFragments / convertRate);
	const convertFragments = shards * convertRate;
	useEffect(() => {
		if (!isNaN(roundDown)) {
			setShards(roundDown);
		}
	}, [roundDown]);
	const onClickConvert = () => dispatch(postConvert(convertFragments, shards));
	const onClickBack = () => {
		dispatch(toggleModal({ name: tag }));
	};
	const onClickMin = () => {
		setShards((prev) => {
			if (prev === 1) return prev;
			return (prev -= 1);
		});
	};
	const onClickPlus = () => {
		setShards((prev) => {
			if (prev === convertFragments) return prev;
			return (prev += 1);
		});
	};
	return (
		<BaseModal tag={tag} title={localize("txt_system")}>
			<div className="title">{localize("txt_popup_confirm_title")}</div>
			<div
				className="text gunar"
				dangerouslySetInnerHTML={{
					__html: localize("txt_popup_confirm_exchange_emblem")
						?.replace("{img}", `<div class="shard-ico" ></div>`)
						?.replace("{img}", `<div class="emblem-ico" ></div>`)
						.replace("{num}", convertFragments)
						.replace("{num}", shards),
				}}
			/>
			<div className="exchange">
				<span>{convertFragments}</span> <div className="shards-ico" />
				<div className="arrow" />{" "}
				<div className="group">
					<button
						onClick={onClickMin}
						className="btn btn-minus"
						disabled={shards <= 1}
					></button>{" "}
					<span>{shards}</span>
					<div className="emblem-ico" />
					<button
						onClick={onClickPlus}
						className="btn btn-plus"
						disabled={roundDown === shards}
					></button>
				</div>
			</div>
			<div className="group">
				<button className="btn btn-grey" onClick={onClickBack}>
					{localize("txt_popup_btn_back")}
				</button>
				<button
					className="btn btn-yellow"
					onClick={onClickConvert}
					disabled={shards < 1}
				>
					{localize("txt_confirm")}
				</button>
			</div>
		</BaseModal>
	);
};

export default ConfirmShards;
