import React, { useState } from "react";
import BaseModal from "helper/component/basemodal";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";
import { useLocalize } from "helper";
import { postSpin } from "redux/actions/main";

const tag = "confirm-spin";

const ConfirmSpin = () => {
	const dispatch = useDispatch();
	const [check, setCheck] = useState(false);
	const [localize] = useLocalize();
	const selectedSpin = useSelector((state) => state.main.selectedSpin);
	const selectedPrice = useSelector(
		(state) => state.main[`spin${selectedSpin}`]
	);
	const onClickConfirm = () => {
		window.localStorage.setItem("confirm_skip", check);
		dispatch(postSpin(selectedSpin));
	};
	const onClickBack = () => {
		setCheck(false);
		dispatch(toggleModal({ name: tag }));
	};
	return (
		<BaseModal
			tag={tag}
			title={localize("txt_system")}
			onExit={() => setCheck(false)}
		>
			<div className="title">{localize("txt_popup_confirm_title")}</div>
			<div
				className="text gunar"
				dangerouslySetInnerHTML={{
					__html: localize("txt_popup_confirm_spin")
						.replace("{img}", `<div class="cp-ico" ></div>`)
						.replace("{num}", `<y>${selectedPrice}</y>`)
						.replace("{num}", `<y>${selectedSpin}</y>`),
				}}
			/>
			<div className="message">
				<div
					onClick={() => setCheck((prev) => !prev)}
					className={`checkbox ${check ? "active" : ""}`}
				/>
				<span>{localize("txt_dont_show")}</span>
			</div>
			<div className="group">
				<button className="btn btn-grey" onClick={onClickBack}>
					{localize("txt_popup_btn_back")}
				</button>
				<button className="btn btn-yellow" onClick={onClickConfirm}>
					{localize("txt_confirm")}
				</button>
			</div>
		</BaseModal>
	);
};

export default ConfirmSpin;
