import React from "react"
import BaseModal from "helper/component/basemodal";

const TestModal = () => {
    return (
        <BaseModal tag={"test"} title={true}>
            <p>Test modal component with basemodal helper</p>
        </BaseModal>
    )
}

export default TestModal;
