import React, { useState } from "react";
import BaseModal from "helper/component/basemodal";
import { useLocalize } from "helper";
import { Table } from "component/partials/Table";
import { useDispatch, useSelector } from "react-redux";
import { isValid } from "date-fns";
import {
	getInventories,
	getShardHistories,
	getSpinHistories,
} from "redux/actions/main";

const mapString = (key) => {
	const value = isValid(new Date(key)) ? key : `txt_${key}`;
	return value?.toLowerCase();
};

const Inventory = () => {
	const [localize] = useLocalize();
	const inventory = useSelector((state) => state.main.inventory);
	return (
		<Table heads={["txt_item", "txt_qty", "txt_expiry_date"]}>
			{inventory.map((item, idx) => {
				return (
					<tr key={idx}>
						<td>{localize(`txt_${item.item_type}`)}</td>
						<td>{item.count}</td>
						<td>{localize(mapString(item.expiry))}</td>
					</tr>
				);
			})}
		</Table>
	);
};

const Item = () => {
	const items = useSelector((state) => state.main.spinHistory);
	const [localize] = useLocalize();
	return (
		<Table heads={["txt_item", "txt_qty", "txt_info"]}>
			{items.map((item, idx) => {
				return (
					<tr key={idx}>
						<td>{item.item_name}</td>
						<td>{item.item_cnt}x</td>
						<td>
							{item.status ? localize("txt_success") : localize("txt_pending")}
						</td>
					</tr>
				);
			})}
		</Table>
	);
};

const Shard = () => {
	const shards = useSelector((state) => state.main.shardHistory);
	const [localize] = useLocalize();

	return (
		<Table heads={["txt_item", "txt_qty", "txt_info", "txt_balance"]}>
			{shards.map((shard, idx) => {
				const text = `${localize("txt_secret_shards")} ${localize(
					mapString(shard.expiry)
				)}`;
				const textInfo = localize(`txt_${shard.info}`);
				return (
					<tr key={idx}>
						<td>{text}</td>
						<td>{shard.qty}</td>
						<td>{textInfo}</td>
						<td>{shard.balance}</td>
					</tr>
				);
			})}
		</Table>
	);
};

const History = () => {
	const dispatch = useDispatch();
	const [tab, setTab] = useState("inventory");
	const [localize] = useLocalize();
	const tabClass = (active) =>
		["btn btn-tab gunar", active === tab && "active"].join(" ");
	const onClickTab = (tab) => () => {
		setTab(tab);
		if (tab === "inventory") {
			dispatch(getInventories());
		}
		if (tab === "item") {
			dispatch(getSpinHistories());
		}
		if (tab === "shard") {
			dispatch(getShardHistories());
		}
	};
	const onResetTab = () => {
		setTab("inventory");
	};

	return (
		<BaseModal
			tag={"history"}
			title={localize("txt_popup")}
			onExit={onResetTab}
		>
			<div className="title">{localize("txt_popup_history_title")}</div>
			<div className="tab">
				<div className="tab-header">
					<button
						onClick={onClickTab("inventory")}
						className={tabClass("inventory")}
					>
						{localize("txt_inventory")}
					</button>
					<button onClick={onClickTab("item")} className={tabClass("item")}>
						{localize("txt_history_spin")}
					</button>
					<button onClick={onClickTab("shard")} className={tabClass("shard")}>
						{localize("txt_history_shard")}
					</button>
				</div>
				<div className="tab-content">
					{tab === "inventory" ? <Inventory /> : null}
					{tab === "item" ? <Item /> : null}
					{tab === "shard" ? <Shard /> : null}
				</div>
			</div>
		</BaseModal>
	);
};

export default History;
