import axios from "axios";
import { getRegionByHostname } from "helper";
import { toggleModal, toggleToast } from "./modal";

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
	transformRequest: (data, headers) => {
		delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
		return data;
	},
});

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
	withCredentials: false,
	transformRequest: (data, headers) => {
		delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
		return data;
	},
});

const rarity = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_codm_rarity`,
	withCredentials: false,
	transformRequest: (data, headers) => {
		delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
		return data;
	},
});

export const handleSpin = (count) => {
	return (dispatch) => {
		const isSkipped = JSON.parse(window.localStorage.getItem("confirm_skip"));
		if (isSkipped) {
			dispatch(postSpin(count));
		} else {
			dispatch({
				type: "CONFIRM_SPIN",
				payload: {
					data: count,
				},
			});
		}
	};
};

export const validateHistory = (key) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const data = getState().main[key];
		if (data.length === 0) {
			resolve("history_not_found");
		} else {
			reject("history_found");
		}
	});
};

var time;

export const counter =
	(key, type = "up", data) =>
	(dispatch) => {
		time && clearTimeout(time);
		time = setTimeout(() => {
			return dispatch({
				type: "COUNTER_RESET",
			});
		}, 2000);
		return dispatch({
			type: "COUNTER",
			payload: {
				key: key,
				data: {
					num: data,
				},
			},
		});
	};

export const getInfo = (init = false) => {
	return (dispatch) => {
		axios
			.get("oauth/check_login/", { area: init ? "initialize" : "login" })
			.then((resp) => {
				dispatch(putData("nickname", resp.data.user.codm_nickname));
				if (init) {
					if (
						getRegionByHostname() === "TH" &&
						!window.localStorage.getItem("policy")
					) {
						dispatch(
							toggleModal({
								name: "policy",
								state: true,
							})
						);
					}
				}
				// dispatch(put_data("login", resp.data));
			});
	};
};

export const postConvert = (count, emblem) => (dispatch, getState) => {
	axios.post("/api/convert/", { frag_count: count }).then((resp) => {
		const emblem = getState().main.pools.find((i) => i.item_type === "emblem");

		dispatch({
			type: "CLAIM_REWARDS",
			payload: {
				data: {
					items: [{ ...emblem, qty: resp.data.data }],
					type: "convert",
				},
			},
		});
		dispatch(getWallet());
	});
};

export const getWallet = (init = false) => {
	return (dispatch, getState) => {
		return axios
			.get("api/wallet/", { area: init ? "initialize" : "wallet" })
			.then((resp) => {
				if (!init) {
					const { fragment, emblem, cp } = getState().main.wallet;
					const cFragment = resp.data.data.fragment - fragment;
					const cEmblem = resp.data.data.emblem - emblem;
					const cCp = resp.data.data.cp - cp;
					dispatch(
						counter("fragment", cFragment > 0 ? "up" : "down", cFragment)
					);
					dispatch(counter("emblem", cEmblem > 0 ? "up" : "down", cEmblem));
					dispatch(counter("cp", cCp > 0 ? "up" : "down", cCp));
				}
				dispatch(putData("wallet", resp.data.data));
			});
	};
};

export const getShop = (init = false) => {
	return (dispatch) => {
		return axios
			.get("api/shop/", { area: init ? "initialize" : "shop" })
			.then((resp) => {
				dispatch(
					putData("shop", 
					resp.data.data.map(
						(data) => ({ ...data, rarity : data.rarity.toLowerCase()})
						)
					)
				);
			});
	};
};

export const postShop = (id) => {
	return (dispatch, getState) => {
		const wallet = getState().main.wallet.emblem;
		const current = getState().main.shop.find((f) => f.id === id);
		if (current?.price > wallet) {
			dispatch(
				toggleToast({
					state: true,
					msg: "not_enough_currency",
				})
			);
			return;
		}
		axios.post("api/shop/", { item_id: id }).then((resp) => {
			dispatch({
				type: "CLAIM_REWARDS",
				payload: {
					data: {
						items: [resp.data.data],
						type: "exchange",
					},
				},
			});
		});
	};
};

export const getSpin = (init = false) => {
	return (dispatch) => {
		return axios
			.get("api/spin/", { area: init ? "initialize" : "pools" })
			.then((resp) => {
				dispatch(putData("pools", resp.data.data));
			});
	};
};

export const postSpin = (count) => {
	return (dispatch, getState) => {
		const single = count === 1;
		const wallet = getState().main.wallet.cp;
		const current = getState().main[`spin${count}`];
		if (current > wallet) {
			dispatch(
				toggleToast({
					state: true,
					msg: "not_enough_cp",
				})
			);
			return;
		}
		axios.post("api/spin/", { is_single: single }).then((resp) => {
			dispatch({
				type: "CLAIM_REWARDS",
				payload: {
					data: {
						items: resp.data.data,
						type: "spin",
					},
				},
			});
		});
	};
};

export const getSetting = (init = false) => {
	return (dispatch) => {
		return axios
			.get("api/setting/", { area: init ? "initialize" : "setting" })
			.then((resp) => {
				dispatch({
					type: "INIT_SETTINGS",
					payload: {
						data: resp.data.data,
					},
				});
				// dispatch(put_data("login", resp.data));
			});
	};
};

export const getSpinHistories = () => (dispatch) => {
	dispatch(validateHistory("spinHistory")).then(() => {
		axios.get("/api/spin_history/").then((resp) => {
			dispatch(putData("spinHistory", resp.data.data));
		});
	});
};

export const getShardHistories = () => (dispatch) => {
	dispatch(validateHistory("shardHistory")).then(() => {
		axios.get("/api/inventory_history/").then((resp) => {
			dispatch(putData("shardHistory", resp.data.data));
		});
	});
};

export const getInventories = () => (dispatch) => {
	dispatch(validateHistory("inventory")).then(() => {
		axios.get("/api/inventory/").then((resp) => {
			dispatch(putData("inventory", resp.data.data));
		});
	});
};

export const putData = (key, data) => (dispatch) =>
	dispatch({ type: "PUT_DATA", payload: { key, data } });

/**
 *
 * Example of how to implement multi language
 *
 */
const combineError = (obj) => {
	const error = { ...obj.error_message[0] };
	delete obj.error_message;
	delete error.collection_id;
	return { ...obj, ...error };
};

export const getTranslate = () => {
	return (dispatch) => {
		const region = getRegionByHostname();

		const events = cdn.get(`${region}.json?${Math.random() * 10000}`);
		const commons = common.get(
			`${region}.json?${Math.floor(Math.random() * 10000)}`
		);
		const rarities = rarity.get(
			`data.json?${Math.floor(Math.random() * 10000)}`
		);
		axios
			.all([commons, events, rarities])
			.then((resp) => {
				const json = {
					...resp[0].data,
					...combineError(resp[1].data),
					...resp[2].data,
				};
				dispatch(putData("localize", json));
			})
			.catch((err) => console.error(err));
	};
};
