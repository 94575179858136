const initialState = {
	msg: "",
	closeIcon: true,
	toast: false,
	type: "error",
	name: {},
};

const modal = (state = initialState, { type, payload }) => {
	switch (type) {
		case "TOGGLE_MODAL":
			return {
				...state,
				name: {
					...state.name,
					[payload.name]: payload.state,
				},
			};
		case "TOGGLE_TOAST":
			return {
				...state,
				msg: payload.msg,
				toast: payload.state,
				closeIcon: payload.closeIcon,
				type: "error",
			};
		case "CONFIRM_EXCHANGE": {
			return {
				...state,
				name: {
					...state.name,
					"confirm-exchange": true,
				},
			};
		}
		case "CONFIRM_SPIN": {
			return {
				...state,
				name: {
					...state.name,
					"confirm-spin": true,
				},
			};
		}
		case "CLAIM_REWARDS": {
			return {
				...state,
				name: {
					...state.name,
					"confirm-spin": false,
					"confirm-exchange": false,
					"confirm-shards": false,
					congrats: true,
				},
			};
		}
		default:
			return state;
	}
};

export default modal;
