const initialState = {
	nickname: "Guest",
	spinHistory: [],
	inventory: [],
	shardHistory: [],
	rewards: [],
	shop: [],
	localize: {},
	counter: {},
	wallet: {
		cp: 0,
		emblem: 0,
		fragment: 0,
	},
	convertRate: 1,
	pools: [],
	spin1: 0,
	spin10: 0,
	selectedId: 0,
	selectedSpin: 1,
};

const main = (state = initialState, { type, payload }) => {
	switch (type) {
		case "PUT_DATA":
			return { ...state, [payload.key]: payload.data };
		case "COUNTER_RESET":
			return {
				...state,
				counter: {},
			};
		case "COUNTER":
			return {
				...state,
				counter: {
					...state.counter,
					[payload.key]: payload.data.num,
				},
			};
		case "INIT_SETTINGS": {
			return {
				...state,
				spin1: payload.data.spin_1_price,
				spin10: payload.data.spin_10_price,
				convertRate: payload.data.convert_rate,
			};
		}
		case "CONFIRM_EXCHANGE": {
			return {
				...state,
				selectedId: payload.data.id,
			};
		}
		case "CLAIM_REWARDS": {
			return {
				...state,
				rewards: payload.data.items.map((d) => ({
					...d,
					action: payload.data.type,
				})),
				spinHistory: [],
				shardHistory: [],
				inventory: [],
			};
		}
		case "CONFIRM_SPIN": {
			return {
				...state,
				selectedSpin: payload.data,
			};
		}

		default:
			return state;
	}
};

export default main;
