import React, { useRef, useCallback, useEffect } from "react";
import BaseModal from "helper/component/basemodal";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal } from "redux/actions/modal";
import { useLocalize } from "helper";
import { getWallet } from "redux/actions/main";

const tag = "congrats";

const mapping = {
	exchange: "txt_popup_congrats_buy_item",
	spin: "txt_popup_congrats_you_got",
	convert: "txt_popup_congrats_exchange_emblem",
};

const useFading = ({ delay = 200 }) => {
	const ref = useRef([]);
	const refs = useCallback((arg) => ref.current.push(arg), []);
	ref.current = [];
	useEffect(() => {
		const observer = new MutationObserver(() => {
			ref.current
				.filter((f) => typeof f === "object" && f !== null)
				.forEach((el, index) => {
					const num = index + 1;
					el.style.animationDuration = `${500}ms`;
					el.style.animationDelay = `${num * delay}ms`;
				});
		});
		observer.observe(document.documentElement, {
			subtree: true,
			childList: true,
		});
		return () => {
			observer.disconnect();
		};
	}, [delay]);
	return [refs];
};

const Congrats = () => {
	const dispatch = useDispatch();
	const [ref] = useFading({ delay: 100 });
	const [localize] = useLocalize();
	const rewards = useSelector((state) => state.main.rewards);
	const rewardsFrom = rewards[0]?.action;

	const onClickConfirm = () => {
		dispatch(toggleModal({ name: tag }));
		updateCounter();
	};
	const updateCounter = () => {
		dispatch(getWallet());
	};
	return (
		<BaseModal tag={tag} title={localize("txt_popup")} onExit={updateCounter}>
			<div className="title">{localize("txt_popup_congrats_title")}</div>
			<div className="text">{localize(mapping[rewardsFrom])}</div>
			<div
				className={`items__container ${
					rewards.length > 1 ? "multiple-items" : "single-items"
				}`}
			>
				<div className="items__wrapper">
					<div className="items__content">
						{rewards.map((data, idx) => {
							const frameClass = [
								"item__frame",
								data?.rarity?.toLowerCase(),
								data?.item_type === "fragment" ? "non-item" : "",
							]
								.filter((d) => d)
								.join(" ");
							return (
								<div ref={ref} key={idx} className="item animated fadeIn">
									<div className={frameClass}>
										<div className="item__img">
											<img alt="" src={data.img_url} />
										</div>
									</div>
									<div className="item__name">
										{rewardsFrom === "convert"
											? `${data?.name} x${data?.qty}`
											: data?.name}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<button className="btn btn-spin btn-yellow" onClick={onClickConfirm}>
				{localize("txt_confirm")}
			</button>
		</BaseModal>
	);
};

export default Congrats;
