import React, { lazy, Suspense } from "react";
import { Routes, BrowserRouter, Route } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useSelector } from "react-redux";
// Component

import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import Fallback from "helper/component/fallback";
import { componentLoader } from "helper";

import ModalCont from "component/Modal";
import Toast from "component/partials/Toast";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));

export const Main = () => {
    const localize = useSelector((state) => state.main.localize);

    return (
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <div id="Main" className="main-panel">
                <LoadingScreen />
                <ErrorBoundary fallback={Fallback}>
                    <Suspense fallback={<div />}>
                        <ViewportRestrict text={localize?.txt_viewport_restrict} display={true} type="portrait" />
                        <Loader />
                        <div className="content-container">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </div>
                    </Suspense>
                </ErrorBoundary>
                <ModalCont />
                <Toast />
            </div>
        </BrowserRouter>
    );
};

export default Main;
