import { useEffect, useRef } from "react";

function replaceKeytoVar(key) {
	return key
		?.replace("img_", "--img-")
		?.replace("rarity_", "--img-rarity-")
		?.replace("clr_", "--clr-")
		?.replace(/_/g, "-");
}

// *all asset images are prefixed with img_
function useCSSVariable({ target, list = {} }) {
	const ref = useRef();
	const data = Object.entries(list).filter(
		([key]) => key.includes("img_") || key.includes("rarity_")
	);

	useEffect(() => {
		if (!target) ref.current = document.documentElement;
		else ref.current = target;
		data.forEach(([key, value]) => {
			setCSSVariable({
				target: ref.current,
				key,
				value,
			});
		});
	}, [data, target]);
}

function setCSSVariable({ target, key, value }) {
	if (!target) target = document.documentElement;
	target?.style.setProperty(replaceKeytoVar(key), `url(${value})`);
}

export { useCSSVariable, setCSSVariable };
